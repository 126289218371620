/* global ymaps*/
import React, { Suspense } from "react";
import axios from "axios";
import Header from "../Header/Header";
import Main from "../Main/Main";
import "./App.css";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
  HashRouter,
} from "react-router-dom";
import { CalculateContext, quiz } from "../../context/CalculateContext";
// import { ComandaContext, comanda } from '../../context/ComandaContext';
import { PriceContext, prices } from "../../context/PriceContext";
import { FaqContext, faq } from "../../context/FaqContext";
import {
  CooperationContext,
  cooperation,
} from "../../context/CooperationContext";
import { ContactsContext, contact } from "../../context/ContactsContext";
import { ArticleContext, articles } from "../../context/ArticleContext";
import { NewsContext, news } from "../../context/NewsContext";
import { cases } from "../../context/CaseContext";
import Thanks from "../Thanks/Thanks";
import logoload from "../../images/logoload.png";
import logowebpload from "../../images/logoload.webp";
import logoavifload from "../../images/logoload.avif";
import placemark from "../../images/icon/placemark.png";
import { TarifContext, tarifs } from "../../context/TarifContext";
import {
  PriceOtoplenieContext,
  pricesotopl,
} from "../../context/PriceOtoplenieContext";
import Article from "../Articles/Articles";
import ArticlesText from "../ArticlesText/ArticlesText";

const Marquiz = React.lazy(() => import("../Marquiz/Marquiz"));
const Tarif = React.lazy(() => import("../Tarif/Tarif"));
const Feedback = React.lazy(() => import("../Feedback/Feedback"));
const Type = React.lazy(() => import("../Type/Type"));
const Example = React.lazy(() => import("../Example/Example"));
const Form = React.lazy(() => import("../Form/Form"));
const Calculate = React.lazy(() => import("../Calculate/Calculate"));
const Case = React.lazy(() => import("../Case/Case"));
// const Comanda = React.lazy(() => import('../Comanda/Comanda'));
const Warehouse = React.lazy(() => import("../Warehouse/Warehouse"));
const Garanty = React.lazy(() => import("../Garanty/Garanty"));
const Stages = React.lazy(() => import("../Stages/Stages"));
const FAQ = React.lazy(() => import("../FAQ/FAQ"));
const News = React.lazy(() => import("../News/News"));
const Showroom = React.lazy(() => import("../Showroom/Showroom"));
const Cooperation = React.lazy(() => import("../Cooperation/Cooperation"));
const Contacts = React.lazy(() => import("../Contacts/Contacts"));
const Footer = React.lazy(() => import("../Footer/Footer"));
const Popup = React.lazy(() => import("../Popup/Popup"));
const Calculatemini = React.lazy(() =>
  import("../Calculatemini/Calculatemini")
);
const Policy = React.lazy(() => import("../Policy/Policy"));
const Error = React.lazy(() => import("../Error/Error"));

function App() {
  const [LinkActive, setLinkActive] = React.useState(null);
  const [stage, setStage] = React.useState(1);
  const [isLoad, setLoad] = React.useState(false);
  const [isThanks, setThanks] = React.useState(false);
  const [isCalc, setCalc] = React.useState("");
  const [isFile, setFile] = React.useState(false);
  const [isYaclid, setYaclid] = React.useState();
  const [isUtmSourse, setUtmSourse] = React.useState();
  const [isUtmMedium, setUtmMedium] = React.useState();
  const [isUtmCampaign, setUtmCampaign] = React.useState();
  const [isUtmTerm, setUtmTerm] = React.useState();
  const [isUtmContent, setUtmContent] = React.useState();
  const [isVisitorId, setVisitorId] = React.useState();
  const [isActiveType, setActiveType] = React.useState(false);
  const [isActiveMap, setActiveMap] = React.useState(true);
  const [isOpenPopupForm, setOpenPopupForm] = React.useState(false);
  const [isOpenPopupImg, setOpenPopupImg] = React.useState(false);
  const [isOpenPopupCalc, setOpenPopupCalc] = React.useState(false);
  const [isOpenPopupQuestion, setOpenPopupQuestion] = React.useState(false);
  const [isOpenPopupPolicy, setOpenPopupPolicy] = React.useState(false);
  const [isBig, setBig] = React.useState(false);
  const [isPopupImg, setPopupImg] = React.useState();
  const [radio, setRadio] = React.useState("Да");
  const [isName, setName] = React.useState("газобетон");
  const [isCaseKey, setCaseKey] = React.useState();
  const [isMainFoto, setMainFoto] = React.useState();
  const [isMainFotoWebp, setMainFotoWebp] = React.useState();
  const [isMainFotoAvif, setMainFotoAvif] = React.useState();
  const [isCaseSrc, setCaseSrc] = React.useState();
  const [isCaseTitle, setCaseTitle] = React.useState();
  const [isCaseText, setCaseText] = React.useState();
  const [isCaseType, setCaseType] = React.useState();
  const [isCase, setCase] = React.useState(false);
  const [isContacts, setContacts] = React.useState(false);
  const [slide, setSlide] = React.useState([1, 2, 3, 4]);
  const [slideBig, setSlideBig] = React.useState(1);
  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
  });
  const history = useNavigate();
  const location = useLocation();
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
    });
  };
  window.AMOPIXEL_IDENTIFIER_PARAMS = window.AMOPIXEL_IDENTIFIER_PARAMS || {};
  window.AMOPIXEL_IDENTIFIER_PARAMS.onload = function (pixel_identifier) {
    var visitor_uid = pixel_identifier.getVisitorUid(); // Получаем visitor_uid
    if (visitor_uid) {
      setVisitorId(visitor_uid);
    }
  };
  function readCookie(name) {
    var n = name + "=";
    var cookie = document.cookie.split(";");
    for (var i = 0; i < cookie.length; i++) {
      var c = cookie[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(n) == 0) {
        return c.substring(n.length, c.length);
      }
    }
    return null;
  }
  function onClose() {
    setOpenPopupForm(false);
    setOpenPopupImg(false);
    setOpenPopupCalc(false);
    setOpenPopupQuestion(false);
    setOpenPopupPolicy(false);
    setBig(false);
    setPopupImg("");
    setCase(false);
    setContacts(false);
    isOpenPopupCalc ? setActiveType(false) : <></>;
  }
  function onSubmitForm(body) {
    let formData = new FormData();
    Object.entries(body).forEach((element) => {
      const [key, value] = element;
      formData.append(key, value);
    });
    axios({
      method: "POST",
      url: "./applicationPol1.php",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then(function (response) {
        if (response.data.result === "success") {
          setThanks(true);
          history("/thank-you");
          onClose();
        }
      })
      .then(() => {
        axios({
          method: "POST",
          url: "./leads.php",
          headers: {
            "Access-Control-Allow-Headers": "content-type",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: {
            visitor: isVisitorId,
            tel: body.tel,
            name: body.name,
            mail: body.mail,
            price: body.price,
            metr: body.metr,
            kilometr: body.kilometr,
            question: body.question,
            type: body.type,
            yclid: isYaclid,
            utm_source: isUtmSourse,
            utm_medium: isUtmMedium,
            utm_campaign: isUtmCampaign,
            utm_term: isUtmTerm,
            utm_content: isUtmContent,
          },
        });
      })
      .catch((err) => console.log(err));
  }
  function onSubmitFormOtoplenie(body) {
    let formData = new FormData();
    Object.entries(body).forEach((element) => {
      const [key, value] = element;
      formData.append(key, value);
    });
    axios({
      method: "POST",
      url: "./applicationOtoplenie1.php",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then(function (response) {
        if (response.data.result === "success") {
          setThanks(true);
          history("/thanks");
          onClose();
        }
      })
      .then(() => {
        axios({
          method: "POST",
          url: "./leads1.php",
          headers: {
            "Access-Control-Allow-Headers": "content-type",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: {
            visitor: isVisitorId,
            tel: body.tel,
            name: body.name,
            mail: body.mail,
            price: body.price,
            metr: body.metr,
            kilometr: body.kilometr,
            question: body.question,
            type: body.type,
            yclid: isYaclid,
            utm_source: isUtmSourse,
            utm_medium: isUtmMedium,
            utm_campaign: isUtmCampaign,
            utm_term: isUtmTerm,
            utm_content: isUtmContent,
          },
        });
      })
      .catch((err) => console.log(err));
  }
  function slidePlus() {
    if (slide[0] >= isCaseSrc.length) {
      setSlide([1, 2, 3, 4]);
    } else {
      if (slide[1] >= isCaseSrc.length) {
        setSlide([isCaseSrc.length, 1, 2, 3]);
      } else {
        if (slide[2] >= isCaseSrc.length) {
          setSlide([isCaseSrc.length - 1, isCaseSrc.length, 1, 2]);
        } else {
          if (slide[3] >= isCaseSrc.length) {
            setSlide([
              isCaseSrc.length - 2,
              isCaseSrc.length - 1,
              isCaseSrc.length,
              1,
            ]);
          } else {
            setSlide([slide[0] + 1, slide[1] + 1, slide[2] + 1, slide[3] + 1]);
          }
        }
      }
    }
  }
  function slideMinus() {
    if (slide[0] <= 1) {
      setSlide([isCaseSrc.length, 1, 2, 3]);
    } else {
      if (slide[1] <= 1) {
        setSlide([isCaseSrc.length - 1, isCaseSrc.length, 1, 2]);
      } else {
        if (slide[2] <= 1) {
          setSlide([
            isCaseSrc.length - 2,
            isCaseSrc.length - 1,
            isCaseSrc.length,
            1,
          ]);
        } else {
          if (slide[3] <= 1) {
            setSlide([
              isCaseSrc.length - 3,
              isCaseSrc.length - 2,
              isCaseSrc.length - 1,
              isCaseSrc.length,
            ]);
          } else {
            setSlide([slide[0] - 1, slide[1] - 1, slide[2] - 1, slide[3] - 1]);
          }
        }
      }
    }
  }
  // Hook
  const useOnScreen = (options) => {
    const ref = React.useRef();
    const [visible, setVisible] = React.useState(false);
    React.useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setVisible(true);
        }
      }, options);
      if (ref.current) {
        observer.observe(ref.current);
      }
      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [ref, options]);
    return [ref, visible];
  };
  React.useEffect(() => {
    window.ym(89449186, "hit", location.pathname);
  }, [location]);
  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);
  React.useEffect(() => {
    setSlide([1, 2, 3, 4]);
    setCaseKey(cases.filter((p) => p.type === isName)[0].key);
    setMainFoto(cases.filter((p) => p.type === isName)[0].srcMain);
    setMainFotoWebp(cases.filter((p) => p.type === isName)[0].srcMainWebp);
    setMainFotoAvif(cases.filter((p) => p.type === isName)[0].srcMainAvif);
    setCaseSrc(cases.filter((p) => p.type === isName)[0].src);
    setCaseTitle(cases.filter((p) => p.type === isName)[0].title);
    setCaseText(cases.filter((p) => p.type === isName)[0].text);
    setCaseType(cases.filter((p) => p.type === isName)[0].type);
  }, [isName]);
  React.useEffect(() => {
    setPopupImg({
      webp: contact[slideBig].webp,
      avif: contact[slideBig].avif,
      ordinary: contact[slideBig].src,
    });
  }, [slideBig]);
  const getParam = (param) => {
    const urlParams = new URL(window.location.toString()).searchParams;
    return urlParams.get(param) || "";
  };
  React.useEffect(() => {
    var script = document.createElement("script");
    script.src =
      "https://api-maps.yandex.ru/2.1?apikey=64abed67-8695-40be-b964-8d052a5a06eb&lang=ru-RU&load=Map,Placemark,behavior.ScrollZoom";
    script.type = "text/javascript";
    script.onload = function () {
      setLoad(true);
      setYaclid(readCookie("yclid"));
      function init(ymaps) {
        var myMap = new ymaps.Map("map", {
          center:
            dimensions.width <= 768
              ? [59.8842, 30.365959]
              : [59.885982, 30.359959],
          zoom: 16,
          type: "yandex#map",
        });
        var myPlacemark = new ymaps.Placemark(
          [59.885982, 30.366048],
          {},
          {
            iconLayout: "default#image",
            iconImageHref: `${placemark}`,
            iconImageSize: [89, 112],
            iconImageOffset: [-43, -90],
          }
        );
        function disabledMap() {
          myMap.behaviors.disable("scrollZoom");
          myMap.behaviors.disable("drag");
          setActiveMap(true);
        }
        function enabledMap() {
          myMap.behaviors.enable("scrollZoom");
          myMap.behaviors.enable("drag");
          setActiveMap(false);
        }
        disabledMap();
        myMap.geoObjects.add(myPlacemark);
        window.addEventListener("keydown", (e) => {
          e.key === "Control" ? enabledMap() : disabledMap();
        });
        window.addEventListener("keyup", (e) => {
          disabledMap();
        });
        window.addEventListener("touchstart", (e) => {
          e.touches.length > 1 ? enabledMap() : disabledMap();
        });
        window.addEventListener("touchend", (e) => {
          disabledMap();
        });
      }
      ymaps.ready(init);
    };
    setTimeout(function () {
      document.head.appendChild(script);
    }, 10000);
    getParam("utm_source") === "" || getParam("utm_source") === undefined
      ? setUtmSourse("")
      : setUtmSourse(getParam("utm_source"));
    getParam("utm_medium") === "" || getParam("utm_medium") === undefined
      ? setUtmMedium("")
      : setUtmMedium(getParam("utm_medium"));
    getParam("utm_campaign") === "" || getParam("utm_campaign") === undefined
      ? setUtmCampaign("")
      : setUtmCampaign(getParam("utm_campaign"));
    getParam("utm_term") === "" || getParam("utm_term") === undefined
      ? setUtmTerm("")
      : setUtmTerm(getParam("utm_term"));
    getParam("utm_content") === "" || getParam("utm_content") === undefined
      ? setUtmContent("")
      : setUtmContent(getParam("utm_content"));
  }, []);

  return (
    <div className="App">
      <Header
        logoload={logoload}
        logowebpload={logowebpload}
        logoavifload={logoavifload}
        LinkActive={LinkActive}
        setLinkActive={setLinkActive}
        setOpenPopupForm={setOpenPopupForm}
      />
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route path="*" element={<Error />} />
          <Route
            path="/"
            element={
              <>
                <Main
                  setOpenPopupCalc={setOpenPopupCalc}
                  isLoad={isLoad}
                  setStage={setStage}
                  classTitle="main__title"
                  title={
                    <>
                      Энергоэффективный водяной теплый пол "под&nbsp;ключ" за 1
                      день
                      <span className="main__title_span"> за 399 р/м2</span>
                    </>
                  }
                  subtitle={
                    <span>
                      Рассчитайте подробную смету{" "}
                      <span className="main__subtitle_span">моментально</span>
                    </span>
                  }
                  link={
                    <button
                      className="main__button"
                      onClick={() => {
                        setStage(1);
                        setOpenPopupCalc(true);
                        setCalc("calc");
                      }}
                      type="button"
                    >
                      РАССЧИТАТЬ НА КАЛЬКУЛЯТОРЕ
                    </button>
                  }
                />
                <Type
                  stage={stage}
                  setStage={setStage}
                  setOpenPopupPolicy={setOpenPopupPolicy}
                  radio={radio}
                  setRadio={setRadio}
                  dimensions={dimensions}
                  isActive={isActiveType}
                  setActive={setActiveType}
                  onSubmitForm={onSubmitForm}
                />
                <Example
                  useOnScreen={useOnScreen}
                  dimensions={dimensions}
                  setOpenPopupImg={setOpenPopupImg}
                  setPopupImg={setPopupImg}
                />
                <Form
                  buttonID="roi_3var"
                  setOpenPopupPolicy={setOpenPopupPolicy}
                  titleSubmit="Заявка на 3 СМЕТЫ"
                  onSubmitForm={onSubmitForm}
                  class={"form__container form__smeta"}
                  button="ПОЛУЧИТЬ 3 СМЕТЫ"
                  isFile={isFile}
                  title={
                    <>
                      <h2 className="form__title">
                        Оставьте заявку и получите 3 варианта сметы на водяной
                        теплый пол
                      </h2>
                      <p className="form__subtitle">
                        3 сметы под разный бюджет: эконом, стандарт, премиум
                      </p>
                    </>
                  }
                >
                  <div className="form__file-box">
                    <input
                      className="form__input-form"
                      type="file"
                      name="file"
                      id="file"
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                    <label className="input-form__label" htmlFor="file">
                      <span className="input-form__label_span">
                        ЕСЛИ ЕСТЬ ПЛАН ДОМА, ПРИКРЕПИТЕ ЕГО
                      </span>
                    </label>
                  </div>
                </Form>
                <Case
                  setCalc={setCalc}
                  useOnScreen={useOnScreen}
                  setLinkActive={setLinkActive}
                  setOpenPopupCalc={setOpenPopupCalc}
                  setOpenPopupImg={setOpenPopupImg}
                  setPopupImg={setPopupImg}
                  isMainFoto={isMainFoto}
                  isMainFotoWebp={isMainFotoWebp}
                  isMainFotoAvif={isMainFotoAvif}
                  isCaseKey={isCaseKey}
                  setMainFoto={setMainFoto}
                  setMainFotoWebp={setMainFotoWebp}
                  setMainFotoAvif={setMainFotoAvif}
                  isCaseSrc={isCaseSrc}
                  isCaseTitle={isCaseTitle}
                  isCaseText={isCaseText}
                  isCaseType={isCaseType}
                  isName={isName}
                  setName={setName}
                  setCase={setCase}
                  slide={slide}
                  setSlide={setSlide}
                  slideMinus={slideMinus}
                  slidePlus={slidePlus}
                />
                {/* <CalculateContext.Provider value={quiz}>
              <Calculate
                useOnScreen={useOnScreen}
                setOpenPopupPolicy={setOpenPopupPolicy}
                setLinkActive={setLinkActive}
                radio={radio}
                setRadio={setRadio}
                dimensions={dimensions}
                isActive={true}
                onSubmitForm={onSubmitForm}
              />
            </CalculateContext.Provider> */}
                <Marquiz />
                <Feedback location={location} />
                {/* <ComandaContext.Provider value={comanda}>
        <Comanda/>
      </ComandaContext.Provider> */}
                {/* <PriceContext.Provider value={prices}>
                  <Warehouse
                    PriceContext={PriceContext}
                    useOnScreen={useOnScreen}
                    dimensions={dimensions}
                    setPopupImg={setPopupImg}
                    setOpenPopupImg={setOpenPopupImg}
                    subtitle="Цены ниже рыночных на 20-30%, убедитесь в этом сами"
                  />
                </PriceContext.Provider> */}
                <PriceOtoplenieContext.Provider value={pricesotopl}>
                  <Warehouse
                    PriceContext={PriceOtoplenieContext}
                    useOnScreen={useOnScreen}
                    dimensions={dimensions}
                    setPopupImg={setPopupImg}
                    setOpenPopupImg={setOpenPopupImg}
                    subtitle="Используем оборудование и материалы, которые проверили лично сами за 9 лет работы"
                  />
                </PriceOtoplenieContext.Provider>
                <Form
                  buttonID="roi_consult"
                  setOpenPopupPolicy={setOpenPopupPolicy}
                  titleSubmit="Заявка на консультацию, выезд замерщика, расчет точной сметы"
                  onSubmitForm={onSubmitForm}
                  class={"form__container form__zamer"}
                  button="ОСТАВИТЬ ЗАЯВКУ"
                  title={
                    <h2 className="form__title">
                      Получите консультацию, выезд замерщика, расчет точной
                      сметы БЕСПЛАТНО
                    </h2>
                  }
                />
                <Garanty
                  useOnScreen={useOnScreen}
                  logoload={logoload}
                  logowebpload={logowebpload}
                  logoavifload={logoavifload}
                />
                <Stages
                  title="Этапы монтажа водяного теплого пола"
                  useOnScreen={useOnScreen}
                  isLoad={isLoad}
                  setLinkActive={setLinkActive}
                  setOpenPopupCalc={setOpenPopupCalc}
                />
                <FaqContext.Provider value={faq}>
                  <FAQ
                    useOnScreen={useOnScreen}
                    isLoad={isLoad}
                    setLinkActive={setLinkActive}
                    setOpenPopupQuestion={setOpenPopupQuestion}
                  />
                </FaqContext.Provider>
                <NewsContext.Provider value={news}>
                  <News
                    class="news"
                    useOnScreen={useOnScreen}
                    dimensions={dimensions}
                    title="Мы каждый день"
                  />
                </NewsContext.Provider>
                <Showroom
                  useOnScreen={useOnScreen}
                  dimensions={dimensions}
                  title=" Протестируй работающую котельную с теплыми полами и радиаторами у нас в
                офисе"
                />
                <Form
                  buttonID="roi_testdrive"
                  setOpenPopupPolicy={setOpenPopupPolicy}
                  titleSubmit="Запишитесь на тест драйв"
                  onSubmitForm={onSubmitForm}
                  class={"form__container form__zamer"}
                  button="Записаться"
                  title={
                    <h2 className="form__title">
                      Запишитесь на тест драйв водяных теплых полов у нас в
                      офисе
                    </h2>
                  }
                />
                {/* <ArticleContext.Provider value={articles}>
                  <Article
                    history={history}
                    useOnScreen={useOnScreen}
                    location={location}
                  />
                </ArticleContext.Provider> */}
                <CooperationContext.Provider value={cooperation}>
                  <Cooperation useOnScreen={useOnScreen} />
                </CooperationContext.Provider>
                <Form
                  buttonID="roi_collab"
                  setOpenPopupPolicy={setOpenPopupPolicy}
                  titleSubmit="Заявка на СОТРУДНИЧЕСТВО"
                  onSubmitForm={onSubmitForm}
                  class={"form__container form__cooperation"}
                  button="сотрудничать"
                  title={
                    <>
                      <h2 className="form__title">
                        Оставьте заявку на сотрудничество
                      </h2>
                      <p className="form__subtitle">
                        Выгодные условия сотрудничества выработанные годами
                      </p>
                      <p className="form__text">
                        Все расчеты по Вашим проектам мы делаем бесплатно и
                        предоставляем в любом формате. Вы получите выгодное
                        предложение по цене работ и стоимости материалов.
                      </p>
                    </>
                  }
                />
                <ContactsContext.Provider value={contact}>
                  <Contacts
                    useOnScreen={useOnScreen}
                    setLinkActive={setLinkActive}
                    dimensions={dimensions}
                    setSlideBig={setSlideBig}
                    setContacts={setContacts}
                    setOpenPopupImg={setOpenPopupImg}
                    setPopupImg={setPopupImg}
                    isLoad={isLoad}
                    isActiveMap={isActiveMap}
                  />
                </ContactsContext.Provider>
              </>
            }
          />
          <Route
            path="/articles"
            element={
              <ArticleContext.Provider value={articles}>
                <Article
                  history={history}
                  useOnScreen={useOnScreen}
                  location={location}
                />
              </ArticleContext.Provider>
            }
          />
          <Route
            path="/articles/:id"
            element={
              <ArticleContext.Provider value={articles}>
                <ArticlesText useOnScreen={useOnScreen} location={location} />
              </ArticleContext.Provider>
            }
          />
          <Route
            path="/otoplenie"
            element={
              <>
                <Main
                  classTitle="main__title main__title-otoplenie"
                  setOpenPopupCalc={setOpenPopupCalc}
                  isLoad={isLoad}
                  setStage={setStage}
                  title={
                    <>
                      Монтаж систем отопления и водоснабжения "под&nbsp;ключ"
                      <span className="main__title_span">
                        {" "}
                        с гарантией до 6 лет
                      </span>
                    </>
                  }
                  subtitle="Получите смету с учетом теплопотерь Вашего дома"
                  link={
                    <a className="main__link" href="#calculate">
                      Получить смету
                    </a>
                  }
                />
                <NewsContext.Provider value={news}>
                  <News
                    class="news_otoplenie"
                    useOnScreen={useOnScreen}
                    dimensions={dimensions}
                    title="Снимаем для Вас каждый день"
                  />
                </NewsContext.Provider>
                <Showroom
                  useOnScreen={useOnScreen}
                  dimensions={dimensions}
                  title="Протестируйте вашу будущую систему отопления у нас в шоу-руме. Такого
        нигде не найдете!"
                  subtitle={
                    <p className="section__text">
                      Обещаем, что после встречи вы станете экспертом, не только
                      в отоплении
                    </p>
                  }
                >
                  <ul className="showroom__list">
                    <li>
                      Протестируете все узлы системы водяных теплых полов и
                      отопления
                    </li>
                    <li>
                      Увидите возможности современной автоматизации микроклимата
                      дома
                    </li>
                    <li>
                      Разберем все детали Вашего проекта и выполним
                      теплотехнический расчет, выберем все оборудование и
                      материалы
                    </li>
                    <li>
                      Получите на руки полноценную смету с материалом и
                      работами, включая 100% точную стоимость
                    </li>
                    <li>
                      По итогу встречи станете экспертом и сможете задавать
                      правильные вопросы подрядчикам
                    </li>
                    <li>Приятные бонусы от нас</li>
                  </ul>
                </Showroom>
                <Form
                  setOpenPopupPolicy={setOpenPopupPolicy}
                  titleSubmit="Запишитесь на тест драйв"
                  onSubmitForm={onSubmitFormOtoplenie}
                  class={"form__container form__zamer"}
                  button="Записаться"
                  title={
                    <h2 className="form__title">
                      Запишитесь на тест драйв водяных теплых полов у нас в
                      офисе
                    </h2>
                  }
                />
                <Case
                  useOnScreen={useOnScreen}
                  setLinkActive={setLinkActive}
                  setOpenPopupCalc={setOpenPopupCalc}
                  setOpenPopupImg={setOpenPopupImg}
                  setPopupImg={setPopupImg}
                  isMainFoto={isMainFoto}
                  isMainFotoWebp={isMainFotoWebp}
                  isMainFotoAvif={isMainFotoAvif}
                  isCaseKey={isCaseKey}
                  setMainFoto={setMainFoto}
                  setMainFotoWebp={setMainFotoWebp}
                  setMainFotoAvif={setMainFotoAvif}
                  isCaseSrc={isCaseSrc}
                  isCaseTitle={isCaseTitle}
                  isCaseText={isCaseText}
                  isCaseType={isCaseType}
                  isName={isName}
                  setName={setName}
                  setCase={setCase}
                  slide={slide}
                  setSlide={setSlide}
                  slideMinus={slideMinus}
                  slidePlus={slidePlus}
                />
                <Stages
                  title="Этапы монтажа системы отопления"
                  useOnScreen={useOnScreen}
                  isLoad={isLoad}
                  setLinkActive={setLinkActive}
                  setOpenPopupCalc={setOpenPopupCalc}
                />
                <TarifContext.Provider value={tarifs}>
                  <Tarif
                    useOnScreen={useOnScreen}
                    dimensions={dimensions}
                    setOpenPopupImg={setOpenPopupImg}
                    setPopupImg={setPopupImg}
                  />
                </TarifContext.Provider>
                <Marquiz />
                <Feedback location={location} />
                <Form
                  setOpenPopupPolicy={setOpenPopupPolicy}
                  titleSubmit="Заявка на 3 СМЕТЫ"
                  onSubmitForm={onSubmitFormOtoplenie}
                  class={"form__container form__smeta"}
                  button="ПОЛУЧИТЬ 3 СМЕТЫ"
                  isFile={isFile}
                  title={
                    <>
                      <h2 className="form__title">
                        Получите расчет сметы для Вашего дома в 3 вариантах
                      </h2>
                      <p className="form__subtitle">
                        с учетом теплопотерь, режима проживания и пожеланий
                      </p>
                    </>
                  }
                >
                  <div className="form__file-box">
                    <input
                      className="form__input-form"
                      type="file"
                      name="file"
                      id="file"
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                    <label className="input-form__label" htmlFor="file">
                      <span className="input-form__label_span">
                        ЕСЛИ ЕСТЬ ПЛАН ДОМА, ПРИКРЕПИТЕ ЕГО
                      </span>
                    </label>
                  </div>
                </Form>
                <PriceOtoplenieContext.Provider value={pricesotopl}>
                  <Warehouse
                    PriceContext={PriceOtoplenieContext}
                    useOnScreen={useOnScreen}
                    dimensions={dimensions}
                    setPopupImg={setPopupImg}
                    setOpenPopupImg={setOpenPopupImg}
                    subtitle="Используем оборудование и материалы, которые проверили лично сами за 9 лет работы"
                  />
                </PriceOtoplenieContext.Provider>
                <Form
                  setOpenPopupPolicy={setOpenPopupPolicy}
                  titleSubmit="Заявка на консультацию, выезд замерщика, расчет точной сметы"
                  onSubmitForm={onSubmitFormOtoplenie}
                  class={"form__container form__zamer"}
                  button="ОСТАВИТЬ ЗАЯВКУ"
                  title={
                    <h2 className="form__title">
                      Получите консультацию, выезд замерщика, расчет точной
                      сметы БЕСПЛАТНО
                    </h2>
                  }
                />
                <Garanty
                  useOnScreen={useOnScreen}
                  logoload={logoload}
                  logowebpload={logowebpload}
                  logoavifload={logoavifload}
                />
                <FaqContext.Provider value={faq}>
                  <FAQ
                    useOnScreen={useOnScreen}
                    isLoad={isLoad}
                    setLinkActive={setLinkActive}
                    setOpenPopupQuestion={setOpenPopupQuestion}
                  />
                </FaqContext.Provider>
                <CooperationContext.Provider value={cooperation}>
                  <Cooperation useOnScreen={useOnScreen} />
                </CooperationContext.Provider>
                <Form
                  setOpenPopupPolicy={setOpenPopupPolicy}
                  titleSubmit="Заявка на СОТРУДНИЧЕСТВО"
                  onSubmitForm={onSubmitFormOtoplenie}
                  class={"form__container form__cooperation"}
                  button="сотрудничать"
                  title={
                    <>
                      <h2 className="form__title">
                        Оставьте заявку на сотрудничество
                      </h2>
                      <p className="form__subtitle">
                        Выгодные условия сотрудничества выработанные годами
                      </p>
                      <p className="form__text">
                        Все расчеты по Вашим проектам мы делаем бесплатно и
                        предоставляем в любом формате. Вы получите выгодное
                        предложение по цене работ и стоимости материалов.
                      </p>
                    </>
                  }
                />
                <ContactsContext.Provider value={contact}>
                  <Contacts
                    useOnScreen={useOnScreen}
                    setLinkActive={setLinkActive}
                    dimensions={dimensions}
                    setSlideBig={setSlideBig}
                    setContacts={setContacts}
                    setOpenPopupImg={setOpenPopupImg}
                    setPopupImg={setPopupImg}
                    isLoad={isLoad}
                    isActiveMap={isActiveMap}
                  />
                </ContactsContext.Provider>
              </>
            }
          />
          <Route
            path="/thanks"
            element={isThanks ? <Thanks /> : <Navigate to="/" />}
          />
          <Route
            path="/thank-you"
            element={isThanks ? <Thanks /> : <Navigate to="/" />}
          />
        </Routes>
        <Popup onClose={onClose} isOpen={isOpenPopupForm} name="form">
          <Form
            setOpenPopupPolicy={setOpenPopupPolicy}
            titleSubmit="Заявка на 3 СМЕТЫ"
            onSubmitForm={
              location.pathname === "otoplenie"
                ? onSubmitFormOtoplenie
                : onSubmitForm
            }
            buttonID="roi_call"
            class={"form__container form__popup-zayavka"}
            button="ПОЛУЧИТЬ СМЕТУ В 3х ВАРИАНТАХ"
            isFile={isFile}
            title={
              <>
                <h2 className="form__title">
                  Расчитайте смету по телефону со специалистом
                </h2>
                <p className="form__text">
                  Оставьте ваш номер телефона, специалист позвонит вам в течение
                  10 мин и задаст несколько вопросов
                </p>
              </>
            }
          >
            <div className="form__file-box">
              <input
                className="form__input-form"
                type="file"
                name="file"
                id="file"
                onChange={(e) => setFile(e.target.files[0])}
              />
              <label className="input-form__label" htmlFor="file">
                <span className="input-form__label_span">
                  ЕСЛИ ЕСТЬ ПЛАН ДОМА, ПРИКРЕПИТЕ ЕГО
                </span>
              </label>
            </div>
          </Form>
        </Popup>
        <Popup onClose={onClose} isOpen={isOpenPopupQuestion} name="question">
          <Form
            buttonID="roi_engin"
            titleSubmit="Вопрос"
            onSubmitForm={
              location.pathname === "otoplenie"
                ? onSubmitFormOtoplenie
                : onSubmitForm
            }
            class={"form__container form__popup-question"}
            button="ЗАДАТЬ ВОПРОС"
            title={
              <>
                <h2 className="form__title">Задайте свой вопрос специалисту</h2>
                <p className="form__text">
                  Оставьте ваш номер телефона, специалист позвонит Вам в течение
                  10 мини ответит на Ваш вопрос
                </p>
              </>
            }
          ></Form>
        </Popup>
        <Popup onClose={onClose} isOpen={isOpenPopupCalc} name="calculate">
          <div className="popup__calculate">
            <Calculatemini
              stage={stage}
              setStage={setStage}
              calcPopupForm={true}
              setOpenPopupPolicy={setOpenPopupPolicy}
              setRadio={setRadio}
              radio={radio}
              title={"Калькулятор-мини всплывающее окно"}
              buttonID={isCalc === "calc" ? "roi_calc" : "roi_project"}
              onSubmitForm={
                location.pathname === "otoplenie"
                  ? onSubmitFormOtoplenie
                  : onSubmitForm
              }
              isForm={true}
              isQuiz={true}
              class="calculate-mini calculate-mini_active calculate-mini_popup"
              isActive={isActiveType}
              setActive={setActiveType}
              button="Получить смету на почту"
              subtitle={
                <>
                  <h3 className="calculate-mini__title">
                    Смета автоматически сформирована, мы готовы выслать
                    Вам ее на почту
                  </h3>
                  <p className="calculate-mini__text">
                    Обещаем звонить и писать только по делу!
                  </p>
                </>
              }
            />
          </div>
        </Popup>
        <Popup onClose={onClose} isOpen={isOpenPopupPolicy} name="policy">
          <Policy />
        </Popup>
        <Popup onClose={onClose} isOpen={isOpenPopupImg} name="image">
          {isOpenPopupImg ? (
            <picture>
              <source srcSet={isPopupImg.webp} type="image/webp" />
              <source srcSet={isPopupImg.avif} type="image/avif" />
              <source srcSet={isPopupImg.ordinary} type="image/jpeg" />
              <img
                className={isBig ? "popup__img popup__img_big" : "popup__img"}
                onClick={() => setBig(!isBig)}
                src={isPopupImg.ordinary}
                alt="Фото"
                type="image/jpeg"
              />
            </picture>
          ) : null}
          {isCase ? (
            <>
              <button
                className="popup__arrow popup__arrow_left"
                onClick={() => {
                  slideMinus();
                  slide[0] === 1
                    ? setMainFoto(isCaseSrc[21].big)
                    : setMainFoto(isCaseSrc[slide[0] - 2].big);
                  slide[0] === 1
                    ? setPopupImg({
                        webp: isCaseSrc[21].bigwebp,
                        avif: isCaseSrc[21].bigavif,
                        ordinary: isCaseSrc[21].big,
                      })
                    : setPopupImg({
                        webp: isCaseSrc[slide[0] - 2].bigwebp,
                        avif: isCaseSrc[slide[0] - 2].bigavif,
                        ordinary: isCaseSrc[slide[0] - 2].big,
                      });
                }}
              ></button>
              <button
                className="popup__arrow popup__arrow_right"
                onClick={() => {
                  slidePlus();
                  slide[0] === 22
                    ? setMainFoto(isCaseSrc[0].big)
                    : setMainFoto(isCaseSrc[slide[0]].big);
                  slide[0] === 22
                    ? setPopupImg({
                        webp: isCaseSrc[0].bigwebp,
                        avif: isCaseSrc[0].bigavif,
                        ordinary: isCaseSrc[0].big,
                      })
                    : setPopupImg({
                        webp: isCaseSrc[slide[0]].bigwebp,
                        avif: isCaseSrc[slide[0]].bigavif,
                        ordinary: isCaseSrc[slide[0]].big,
                      });
                }}
              ></button>
            </>
          ) : null}
          {isContacts ? (
            <>
              <button
                className="popup__arrow popup__arrow_left"
                onClick={() => {
                  slideBig < 1
                    ? setSlideBig(contact.length - 1)
                    : setSlideBig(slideBig - 1);
                }}
              ></button>
              <button
                className="popup__arrow popup__arrow_right"
                onClick={() => {
                  slideBig > contact.length - 2
                    ? setSlideBig(0)
                    : setSlideBig(slideBig + 1);
                }}
              ></button>
            </>
          ) : null}
        </Popup>
        <Footer
          logoload={logoload}
          logowebpload={logowebpload}
          logoavifload={logoavifload}
          setOpenPopupPolicy={setOpenPopupPolicy}
          setOpenPopupForm={setOpenPopupForm}
          setLinkActive={setLinkActive}
        />
      </Suspense>
    </div>
  );
}

export default App;
